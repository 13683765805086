<template>
  <div class="fill-heigt" style="background-color: #fff">
    <v-container class="fill-heigt" fluid>
      <v-row class="pb-0">
        <v-btn icon color="primary" @click="routerBack()">
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn class="ma-1" color="primary" @click="openShareDialog()">
          {{ $t("share") }}
        </v-btn>
      </v-row>
    </v-container>
    <div>
      <v-container class="afinar">
        <vue-html2pdf
          style="height: 2400px; margin-top: -3%"
          :show-layout="false"
          :float-layout="true"
          :enable-download="false"
          :preview-modal="true"
          :paginate-elements-by-height="1200"
          :filename="candidateId"
          :pdf-quality="4"
          :manual-pagination="true"
          pdf-format="a4"
          :html-to-pdf-options="htmlToPdfOptions"
          @progress="onProgress($event)"
          @hasStartedGeneration="hasStartedGeneration()"
          @hasGenerated="hasGenerated($event)"
          ref="html2Pdf"
        >
        </vue-html2pdf>
      </v-container>
    </div>
    <ShareCVDialog />
  </div>
</template>

<script>
import { GET_CANDIDATE_BY_ID_QUERY } from "./../../candidate/graphql/Query";
import VueHtml2pdf from "../../../components/vue-html2pdf.vue";
import { mapGetters } from "vuex";
import moment from "moment";
import { API_URL } from "@/api";
import ShareCVDialog from "../components/ShareCVDialog.vue";
export default {
  name: "CV",
  components: { ShareCVDialog, VueHtml2pdf },
  data: () => ({
    apiUrl: API_URL,
    candidateId: undefined,
    candidate: {},
    skipQuery: true,
    publicPath: process.env.BASE_URL,
    filter: {
      jobType:'job',
      areasInterest: undefined,
      belongsToMany: undefined,
      favorites: undefined,
      formation: [
        {
          course: undefined,
          level: undefined,
        },
      ],
      languages: [
        {
          name: undefined,
          level: undefined,
        },
      ],
      ids: undefined,
      limit: 4,
      maxYear: undefined,
      minYear: undefined,
      maxSalary: undefined,
      minSalary: undefined,
      page: 1,
      professionalExperience: undefined,
    },
    htmlToPdfOptions: {
      margin: 0,
      filename: `hehehe.pdf`,
      image: {
        type: "jpeg",
        quality: 4,
      },
      enableLinks: true,
      html2canvas: {
        scale: 5,
        useCORS: true,
      },
      // pagebreak: { mode: "avoid-all" },
      jsPDF: {
        format: "letter",
        // floatPrecision: "smart",
        // orientation: "portrait",
      },
    },
  }),
  apollo: {
    candidate: {
      query: GET_CANDIDATE_BY_ID_QUERY,
      variables() {
        return {
          id: this.candidateId,
        };
      },
      skip() {
        return this.skipQuery;
      },
      result({ data, loading }) {
        if (!loading) {
          this.candidate = data.candidate;
        }
      },
    },
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/getCurrentUser",
    }),
    candidate() {
      return this.currentUser ? this.currentUser.candidate : {};
    },
  },
  created() {
    this.start();
  },
  mounted() {},
  methods: {
    formatDate(date) {
      return moment(date).format("DD/MM/YYYY");
    },
    routerBack() {
      switch (this.$route.query.from) {
        case "candidacies":
          this.$router.push({
            path: `/dashboard/entity/candidacies/profile/${this.candidateId}`,
            query: {
              filter: this.filter,
              from: "candidacies",
              vacancyId: this.$route.query.vacancyId,
            },
          });
          break;

      case "candidacies/unsolicited":
          this.$router.push({
            path: `/dashboard/entity/candidacies/unsolicited/`,
            query: { filter: this.filter, vacancyId: this.$route.query.vacancyId },
            from: "candidacies/unsolicited",
          });
        break;

        case "ranking":
          this.$router.push({
            path: `/dashboard/entity/ranking/profile/${this.candidateId}`,
            query: {
              filter: this.filter,
              from: "ranking",
              vacancyId: this.$route.query.vacancyId,
            },
          });
          break;
        case "candidates":
          this.$router.push({
            path: `/dashboard/entity/candidates/profile/${this.candidateId}`,
            query: {
              filter: this.filter,
              from: "entity",
              vacancyId: this.$route.query.vacancyId,
            },
          });
          break;
        default:
          this.$router.back();
      }
    },

    openShareDialog() {
      // eslint-disable-next-line no-undef
      Fire.$emit("shareCandidateCV", this.candidate.id);
    },
    start() {
      this.candidateId = this.$route.params.id;
      this.fetchCandidate();
      this.filter = this.$route.query.filter
        ? { ...this.$route.query.filter }
        : this.filter;
      this.filter.limit = 4;
      console.log(this.filter);
    },
    async fetchCandidate() {
      this.$apollo.queries.candidate.skip = false;
      await this.$apollo.queries.candidate.refetch();
    },
  },
};
</script>
<style scoped>
.afinar {
  max-width: 800px;
  align-content: center;
  margin: auto;
}
</style>
